/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'

import '~styles/app.scss'
import * as s from './style.module.scss'

import Header from '../Header'
import Footer from '../Footer'

const Layout = ({ children, hasFooterSpot = false, hasNoise }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={cn(s.layout, { [s.noise]: hasNoise })}>
      <Header siteTitle={data.site.siteMetadata?.title} />
      <main className="main">{children}</main>
      <Footer siteTitle={data.site.siteMetadata?.title} hasSpot={hasFooterSpot} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
