// extracted by mini-css-extract-plugin
export const hero = "h_s";
export const hero__header = "h_t";
export const hero__built = "h_v";
export const hero__descr = "h_w";
export const home = "h_x";
export const about = "h_h";
export const starter = "h_y";
export const token = "h_z";
export const technology = "h_n";
export const world = "h_B";