const SOCIALS = [
  {
    name: 'tw',
    link: 'https://twitter.com/solclout',
  },
  // { name: 'in', link: '#' },
  // { name: 'm', link: '#' },
  { name: 'tg', link: 'https://t.me/solclout' },
]

export default SOCIALS
