import React, { Fragment } from 'react'
import { Container } from 'react-bootstrap'
import cn from 'classnames'
import * as s from './style.module.scss'
import Social from '~components/Social'

import solana from '~img/solana.svg'

const Hero = ({ title, description, children, hasBuilt = false, modifier }) => {
  return (
    <Container as="section" className={cn(s.hero, s[modifier])}>
      <h1 className={cn('display-1', s.hero__header)}>
        {title.split('&shy;').map((el) => (
          <Fragment key={el}>{el}&shy;</Fragment>
        ))}
      </h1>
      {hasBuilt && (
        <div className={s.hero__built}>
          Built on
          <img src={solana} alt="Solana" />
        </div>
      )}
      <p className={cn(s.hero__descr, s[modifier])}>{description}</p>
      {children}
      <Social modifier="social--hero" />
    </Container>
  )
}

export default Hero
