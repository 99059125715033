const MENU = [
  {
    name: 'About',
    link: '#about',
  },
  {
    name: 'Technology',
    link: '#technology',
  },
  {
    name: 'Feature',
    link: '#feature',
  },
  {
    name: 'SolClout World',
    link: '#world',
  },
  {
    name: 'SolClout Starter',
    link: '#starter',
  },
  {
    name: 'Token',
    link: '#token',
  },
  {
    name: 'Roadmap',
    link: '#roadmap',
  },
  {
    name: 'Team',
    link: '#team',
  },
]

export default MENU
