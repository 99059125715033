import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import cn from 'classnames'

import Social from '~components/Social'
import Menu from '~components/Menu'

import * as s from './style.module.scss'

import logo from '~img/logo.svg'
import sprite from '~img/icons.svg'

const Footer = ({ siteTitle, hasSpot = true }) => {
  gsap.registerPlugin(ScrollToPlugin)

  const [show, setShow] = useState(false)

  const handleScrollTop = () => {
    gsap.to(window, { duration: 1, scrollTo: { y: 0, autoKill: true }, ease: 'power2' })
  }

  const handleButton = () => setShow(window.scrollY > 650)

  useEffect(() => {
    window.addEventListener('scroll', handleButton)
    return () => {
      window.removeEventListener('scroll', handleButton)
    }
  }, [])

  return (
    <>
      <footer className={cn(s.footer, { [s.spot]: hasSpot })}>
        <Container className={s.footer__inner}>
          <div className={s.footer__logo}>
            <div className={s.footer__pic}>
              <img src={logo} alt={siteTitle} />
            </div>
            {siteTitle}
          </div>
          <Menu modifier="footer" />
          <Social modifier="social--footer" />
          <p className={s.footer__copy}>© {new Date().getFullYear()} SolClout. All rights reserved</p>
        </Container>
      </footer>
      <button className={cn(s.footer__button, { [s.show]: show })} onClick={handleScrollTop}>
        <svg xmlns="http://www.w3.org/2000/svg" width={10} height={16} viewBox={`0 0 ${10} ${16}`}>
          <use fill="currentColor" xlinkHref={`${sprite}#arrow_top`} />
        </svg>
      </button>
    </>
  )
}

export default Footer
