import React from 'react'
import { Nav } from 'react-bootstrap'
import { social } from './style.module.scss'
import cn from 'classnames'
import SOCIALS from './constants'
import sprite from '~img/icons.svg'

const Social = ({ modifier }) => {
  return (
    <Nav className={cn('nav--social', `nav--${modifier}`)} as="ul">
      {SOCIALS.map(({ name, link }) => (
        <Nav.Item as="li" key={name}>
          <Nav.Link href={link} target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={`0 0 ${24} ${24}`}>
              <use fill="currentColor" xlinkHref={`${sprite}#${name}`} />
            </svg>
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default Social
