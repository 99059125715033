import * as React from 'react'
import { Link } from 'gatsby'
import { Container, Dropdown } from 'react-bootstrap'
import Menu from '~components/Menu'
import Social from '~components/Social'
import logo from '~img/logo.svg'
import { header, header__inner } from './style.module.scss'

// import sprite from '~img/icons.svg'

const Header = ({ siteTitle }) => (
  <header className={header}>
    <Container className={header__inner}>
      <Link to="/">
        <img src={logo} alt={siteTitle} />
      </Link>
      <Dropdown className="dropdown--header">
        <Dropdown.Toggle as="button">
          <span className="dropdown-hamb"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu--header"
          align="right"
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [8, 24],
                },
              },
            ],
          }}
        >
          <Menu modifier="dropdown" />
          <Social modifier="social--dropdown" />
        </Dropdown.Menu>
      </Dropdown>
      <Menu modifier="header" />
    </Container>
  </header>
)

export default Header
